import distance from '@turf/distance';

function getStopInfo(platformMarkers, stopSignal) {
  let maxDistance;
  let start;
  let stop;

  for (let i = 0; i < platformMarkers.length; i += 1) {
    for (let j = 0; j < platformMarkers.length; j += 1) {
      if (i === j) {
        // eslint-disable-next-line no-continue
        continue;
      }
      
      const startMarker = platformMarkers[i];
      const endMarker = platformMarkers[j];

      const distanceBetweenMarkers = distance(startMarker, endMarker, { units: 'meters' });
      
      if (maxDistance == null || distanceBetweenMarkers > maxDistance) {
        maxDistance = distanceBetweenMarkers;
        start = startMarker;
        stop = endMarker;
      }
    }
  }

  const distanceBetweenStartAndStopSignal = distance(stopSignal, start, { units: 'meters' });

  return {
    platformLength: distance(start, stop, { units: 'meters' }),
    trainOffset: distanceBetweenStartAndStopSignal,
  };
}

export {
  getStopInfo,
};