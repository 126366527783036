import { useEffect } from 'react';

import AppWindow from '@utils/AppWindow/AppWindow';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';

import BasePage from '@pages/BasePage/BasePage';
import AnalyticsCollector from '@App/components/AnalyticsCollector/AnalyticsCollector';
import FeedbackButton from '@App/components/FeedbackButton/FeedbackButton';

import translator from './app.translator';

import './app.styl';

function App() {
  useEffect(() => document.documentElement.setAttribute('data-app-initialised', true), []);

  useEffect(() => {
    const interval = setInterval(() => {
      translator.setLanguage(translator.getLanguage() === 'nl' ? 'fr' : 'nl');
    }, 15_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ErrorBoundary>
      <BasePage />
      <AppWindow />
      <AnalyticsCollector />
      <FeedbackButton />
    </ErrorBoundary>
  );
}

export default App;
