import { faLeftLong, faRightLong } from '@fortawesome/pro-solid-svg-icons';

function getTrainCarIcon(isLoco, hasSeats, isFirstClass, useFlipped) {
  if (!isLoco) {
    return `/img/icons/icons_cart${useFlipped ? '_flipped' : ''}${isFirstClass ? '_1e' : ''}.svg`;
  }

  return `/img/icons/icons_loco${hasSeats ? '_blue' : ''}${useFlipped ? '_flipped' : ''}${isFirstClass ? '_1e' : ''}.svg`;
}

function getIconToUse(orientation, isScreen) {
  if (!isScreen) {
    return orientation === 'LEFT' ? faLeftLong : faRightLong;
  }

  return orientation === 'LEFT' ? faRightLong : faLeftLong;
}

function shouldFlipIcon(orientation, isScreen) {
  return isScreen ? orientation === 'LEFT' : orientation === 'RIGHT';
}

export {
  getTrainCarIcon,
  getIconToUse,
  shouldFlipIcon,
};