import { useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { createPosterAnalytics, updatePosterAnalytics } from '@services/analytics.service';

function AnalyticsCollector() {
  const mounted = useRef(false);
  const analyticsId = useRef(undefined);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    async function createPosterAnalyticEntry(payload) {
      const { data: { id } } = await createPosterAnalytics(payload);
      analyticsId.current = id;
    }

    function updatePosterAnalyticEntry() {
      if (analyticsId.current && navigator.sendBeacon) {
        const sessionEnd = Date.now();
        updatePosterAnalytics(analyticsId.current, { sessionEnd });
      }
    }

    if (!mounted.current) {
      mounted.current = true;
      const posterId = searchParams.get('posterId');

      if (posterId) {
        const sessionStart = Date.now();
        searchParams.delete('posterId');
        setSearchParams(searchParams);
        createPosterAnalyticEntry({ posterId, sessionStart })
      }
    }
    
    window.addEventListener('unload', updatePosterAnalyticEntry);

    return () => window.removeEventListener('unload', updatePosterAnalyticEntry);
  }, [searchParams, setSearchParams]);

  return null;
}

export default AnalyticsCollector;