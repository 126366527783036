import { screen } from '@css/theme.style.json';

function convertPxToNum(val) {
  const numString = val.replace('px', '');

  if (Number.isNaN(numString)) {
    return undefined;
  }

  return parseInt(numString, 10)
}

const MEDIA_BREAKPOINT_ENTRIES = [
  [0, 'XXXS'],
  [convertPxToNum(screen.xxs), 'XXS'],
  [convertPxToNum(screen.xs), 'XS'],
  [convertPxToNum(screen.sm), 'SM'],
  [convertPxToNum(screen.md), 'MD'],
  [convertPxToNum(screen.lg), 'LG'],
  [convertPxToNum(screen.xlg), 'XLG'],
  [Infinity],
];

export {
  MEDIA_BREAKPOINT_ENTRIES,
}