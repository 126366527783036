import { isMobile } from '@services/utils.service';
import { getCurrentBreakpoint } from '@utils/AppWindow/app-window.utils';

const initialBreakpoint = getCurrentBreakpoint();
const MOBILE_BREAKPOINT = 'SM';

const defaultState = {
  isMobile: isMobile(initialBreakpoint, MOBILE_BREAKPOINT),
  resizeProps: {
    mediaBreakpoint: initialBreakpoint,
  },
};

function appWindow(state = defaultState, action) {
  switch (action.type) {
    case 'UPDATE_RESIZE_PROPERTIES': {
      const mediaBreakpoint = getCurrentBreakpoint();

      return {
        ...state,
        isMobile: isMobile(mediaBreakpoint, MOBILE_BREAKPOINT),
        resizeProps: { mediaBreakpoint },
      }
    }
    default:
      return state;
  }
}

export default appWindow;