import { Component } from 'react';

import WaitingScreen from '@App/components/WaitingScreen/WaitingScreen';

import './error-boundary.styl';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }
  
  render() {
    const { message } = this.props;
    const { error, errorInfo } = this.state

    if (errorInfo) {
      if (import.meta.env.VITE_NAZKA_ENV === 'development') {
        return (
          <div className="error-boundary">
            <div className="error-boundary__content">
              <h2 className="error-boundary__content__title">Something went wrong</h2>
              <div className="error-boundary__content__error">
                { message || (error && error.toString()) }
              </div>
              { 
                !message && errorInfo && errorInfo.componentStack 
                && (
                  <details className="error-boundary__content__stack">
                    <summary>Details</summary>
                    <pre>
                      <code>{errorInfo.componentStack}</code>
                    </pre>
                  </details>
                )
              }
            </div>
          </div>
        );
      }

      return (
        <WaitingScreen />
      );
    }

    return this.props.children;
  }  
}

export default ErrorBoundary;