import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

import TranslationsProvider from '@utils/i18n/i18n';
import queryClient from '@utils/QueryClient/QueryClient';

import store from '@App/app.store';
import translator from '@App/app.translator';

import App from './App/App';

import './index.styl';

const container = document.getElementById('js-mapframe');
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <TranslationsProvider translator={translator}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </TranslationsProvider>
    </Provider>
  </QueryClientProvider>,
);