import { useTranslation } from '@utils/i18n/i18n';
import Credits from '@App/components/Credits/Credits';

import './departure-details.styl';

const formatter = new Intl.DateTimeFormat('nl-BE', {
  timeZone: 'Europe/Brussels',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
});

function formatDate(date) {
  return formatter.format(date);
}

function DepartureDetails({
  delay,
  departureTimestamp,
  destination,
  platform,
}) {
  const { t } = useTranslation();

  const departureDate = new Date(departureTimestamp);
  const departureTime = formatDate(departureDate);

  return (
    <div className="departure-details">
      <div className="departure-details__header title-text">
        <div className="departure-details__header__left">
          <div className="departure-details__header__departure">
            {departureTime}
          </div>
          {!!delay && (
            <div className="departure-details__header__delay">
              <span>
                +
                {Math.round(delay / 60)}
                ’
              </span>
              <span>
                {formatDate(new Date(departureTimestamp + (delay * 1000)))}
              </span>
            </div>
          )}
        </div>
        <span className="departure-details__header__platform">
          {platform}
        </span>
      </div>
      <div>
        <h2 className="departure-details__destination title-text">
          {destination}
        </h2>
        <div className="departure-details__stops">
          <span>
            {t('via Namen')}
          </span>
        </div>
      </div>
      <Credits />
    </div>
  );
}

export default DepartureDetails;