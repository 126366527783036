import distance from '@turf/distance';

import './platform-info-zones.styl';

function PlatformInfoZones({
  platformMarkers,
  platformLength,
  isScreen,
}) {
  return (
    <div className="platform-info-zones">
      {platformMarkers.map((platformMarker, index) => {
        if (index === platformMarkers.length - 1) {
          return null;
        }

        const firstMarker = platformMarker;
        const secondMarker = platformMarkers[index + 1];

        const zoneWidth = distance(firstMarker, secondMarker, { units: 'meters' });
        const zoneWidthPercentage = `${(zoneWidth / platformLength) * 100}%`;

        const refSplit = firstMarker.properties.ref.split(';');
        const zoneName = refSplit[isScreen ? 0 : refSplit.length - 1];

        return (
          <div
            className="platform-info-zones__zone"
            key={platformMarker._id}
            style={{
              width: zoneWidthPercentage,
            }}
          >
            {`Zone ${zoneName.replace('7', '')}`}
          </div>
        )
      })}
    </div>
  );
}

export default PlatformInfoZones;