import { faCommentSmile } from '@fortawesome/pro-solid-svg-icons';
import { useWindowSize } from '@uidotdev/usehooks';

import Icon from '@library/Icon/Icon';
import { useIsScreen } from '@services/hooks.service';

import theme from '@css/theme.style.json';

import './feedback-button.styl';

function FeedbackButton() {
  const isScreen = useIsScreen();
  const windowSize = useWindowSize();

  if (isScreen) {
    return null;
  }

  if (windowSize.height <= 580) {
    return null;
  }

  return (
    <a type="button" className="feedback-button" href="mailto:bikeontrain@belgiantrain.be">
      <Icon
        icon={faCommentSmile}
        color={theme.color.primary.main}
        size={24}
      />
      <span className="feedback-button__text">
        Feedback
      </span>
    </a>
  );
}

export default FeedbackButton;