import { MEDIA_BREAKPOINT_ENTRIES } from './app-window.constants';

function onResizeViewport() {
  const vh = window.innerHeight * 0.01;
  const vw = window.innerWidth * 0.01;
  document.body.style.setProperty('--vh', `${vh}px`);
  document.body.style.setProperty('--vw', `${vw}px`);
  return {
    '--vh': vh,
    '--vw': vw,
  };
}

function getCurrentBreakpoint() {
  return MEDIA_BREAKPOINT_ENTRIES[
    MEDIA_BREAKPOINT_ENTRIES.findIndex(entry => (entry[0] > window.innerWidth)) - 1
  ][1];
}

export {
  onResizeViewport,
  getCurrentBreakpoint,
}