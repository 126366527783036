import { QueryClient } from '@tanstack/react-query';

function createQueryClient() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });

  return queryClient;
}

const queryClient = createQueryClient();
export default queryClient;