/* eslint-disable max-len */
import { useTranslation } from '@utils/i18n/i18n';

import './waiting-screen.styl';

function WaitingScreen() {
  const { t } = useTranslation();

  return (
    <div className="waiting-screen">
      <div className="waiting-screen__header">
        <img src="/img/logo/sncb.svg" />
        <span>SNCB</span>
      </div>
      <main className="waiting-screen__content">
        <div className="waiting-screen__content__text">
          <h2>
            {t('De beste plek om op de trein naar Aarlen te wachten?')}
          </h2>
          <p>
            {t('Ben je op zoek naar de beste plek op dit perron om op de trein te wachten omdat het instappen stress geeft?')}
          </p>
          <p>
            {t('Wil je graag instappen in 1e klas of in het fietsrijtuig?')}
          </p>
          <p>
            {t('We testen een oplossing om je te helpen de beste plaats te vinden op het perron voor treinen richting Aarlen.')}
          </p>
          <p>
            {t('Enkele minuten voor de aankomst van de trein naar Aarlen krijg je meer informatie over de samenstelling van de trein en in welke zone je best wacht op het perron.')}
          </p>
        </div>
        <picture className="waiting-screen__content__image">
          <source srcSet="/img/misc/poster-image-mobile.png" media="(max-width: 992px)" />
          <img src="/img/misc/poster-image.svg" alt="NMBS platform guidance poster image" />
        </picture>
      </main>
      <footer className="waiting-screen__footer">
        © 2024 SNCB
      </footer>
    </div>
  );
}

export default WaitingScreen;