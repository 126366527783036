function getFlexDirection(isScreen, trainDirection) {
  if (!isScreen) {
    return trainDirection === 'RIGHT' ? 'row-reverse' : 'row';
  }

  return trainDirection === 'LEFT' ? 'row-reverse' : 'row';
}

function getMarginLeft(isScreen, compositionOffset, compositionWidth, trainDirection) {
  if (!isScreen) {
    return trainDirection === 'RIGHT' ? undefined : compositionOffset;
  }

  return trainDirection === 'RIGHT' ? undefined : (100 - compositionWidth - compositionOffset);
}

function getMarginRight(isScreen, compositionOffset, compositionWidth, trainDirection) {
  if (!isScreen) {
    return trainDirection === 'LEFT' ? undefined : compositionOffset;
  }

  return trainDirection === 'LEFT' ? undefined : (100 - compositionWidth - compositionOffset);
}

export {
  getFlexDirection,
  getMarginLeft,
  getMarginRight,
};