import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

function usePlatformInfo(station, platform, lang) {
  return useQuery({
    queryKey: ['platform-info', station, platform, lang],
    queryFn: async () => {
      const { data } = await axios.get(`/api/platform-info?id=${station}&platform=${platform}&lang=${lang}`);

      return data;
    },
    enabled: !!station && !!platform,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
  });
}

function usePlatformMarkers(station, platform) {
  return useQuery({
    queryKey: ['platform-markers', station, platform],
    queryFn: async () => {
      const { data } = await axios.get(`/api/platform-info/markers?id=${station}&platform=${platform}`);

      return data;
    },
    enabled: !!station && !!platform,
  });
}

export {
  usePlatformInfo,
  usePlatformMarkers,
};