import axios from 'axios';

/* eslint-disable */
if (!window.GA_MEASUREMENT_ID) {
  console.log('Google Analytics isn\'t setup for production yet.');
}

function initGoogleAnalytics(advancedAnalyticsBoolean) {
  if (window.GA_MEASUREMENT_ID) {
    window[`ga-disable-${window.GA_MEASUREMENT_ID}`] = false;

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() { dataLayer.push(arguments); }
    
    gtag('js', new Date());
    gtag('config', window.GA_MEASUREMENT_ID, { 
      anonymize_ip: !advancedAnalyticsBoolean,
      debug_mode: import.meta.env.VITE_NAZKA_ENV !== 'production',
    });
  }
}

function createPosterAnalytics(payload) {
  return axios.put(`/api/analytics/poster`, payload);
}

function updatePosterAnalytics(id, update) {
  if (navigator.sendBeacon) {
    navigator.sendBeacon(`/api/analytics/poster/${id}`, JSON.stringify(update));
  }
}

export { initGoogleAnalytics, createPosterAnalytics, updatePosterAnalytics };