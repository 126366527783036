import { useSearchParams } from 'react-router-dom';

import DepartureDetails from '@components/DepartureDetails/DepartureDetails';
import PlatformInfo from '@App/components/PlatformInfo/PlatformInfo';
import WaitingScreen from '@App/components/WaitingScreen/WaitingScreen';

import { usePlatformInfo, usePlatformMarkers } from '@utils/QueryClient/platform-info';
import { useTranslation } from '@utils/i18n/i18n';
import { useIsScreen } from '@services/hooks.service';

import './base-page.styl';

function BasePage() {
  const { lang, t } = useTranslation();
  const [queryParams] = useSearchParams();

  const station = queryParams.get('station');
  const platform = queryParams.get('platform');
  const isScreen = useIsScreen();

  const { data: dutchPlatformInfo, isLoading: dutchPlatformInfoLoading } = usePlatformInfo(station, platform, 'nl');
  const { data: frenchPlatformInfo, isLoading: frenchPlatformInfoLoading } = usePlatformInfo(station, platform, 'fr');
  const { data: platformMarkers, isLoading: platformMarkersLoading } = usePlatformMarkers(station, platform);

  if (!station || !platform) {
    return (
      <div className="base-page base-page--error">
        {t('Geen station/platform opgegeven')}
      </div>
    );
  }

  if (dutchPlatformInfoLoading || frenchPlatformInfoLoading || platformMarkersLoading) {
    return (
      <div className="base-page is-loading" />
    );
  }

  if ((lang === 'nl' && !dutchPlatformInfoLoading && !dutchPlatformInfo)
    || (lang === 'fr' && !frenchPlatformInfoLoading && !frenchPlatformInfo)
    || (!platformMarkersLoading && !platformMarkers)) {
    return (
      <WaitingScreen />
    );
  }

  const { departureInfo, vehicleComposition, stopSignal } = lang === 'nl' ? dutchPlatformInfo : frenchPlatformInfo;

  if (queryParams.get('waitingScreen') !== 'false'
    && (departureInfo.canceled || Date.now() <= departureInfo.departureTimestamp - (15 * 60 * 1000))) {
    return (
      <WaitingScreen />
    );
  }

  return (
    <div className="base-page">
      <div className={`base-page__content${isScreen ? ' base-page__content--screen' : ''}`}>
        <PlatformInfo
          composition={vehicleComposition}
          stopSignal={stopSignal}
          platformMarkers={platformMarkers}
        />
        <DepartureDetails
          delay={departureInfo.delay}
          departureTimestamp={departureInfo.departureTimestamp}
          destination={departureInfo.destination}
          platform={platform}
        />
      </div>
    </div>
  );
}

export default BasePage;